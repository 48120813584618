import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import axios from "axios";

export const Landingpage = () => {
    const googleClientId = "428906911177-kfh1sruu3a1q5s1n0m108f6n880k96na.apps.googleusercontent.com";

    const handleLoginSuccess = async (response: any) => {
        const token = response.credential;
        console.log("Loging token: ", token);
        const res = await axios.post("http://localhost:50505/login", { token });
        console.log(res);
    };

    return (
        <GoogleOAuthProvider clientId={googleClientId}>
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100">
                <h1 className="tw-text-3xl tw-font-bold tw-mb-4">Social Synapse</h1>
                <div>Welcome to the landing page.</div>
                <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={() => console.log("Login Failed")}
                    type="standard"
                    theme="outline"
                    size="large"
                    text="signin_with"
                    shape="rectangular"
                    logo_alignment="center"
                    width="300"
                    locale="en"
                />
            </div>
        </GoogleOAuthProvider>
    );
};
