import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

export const Layout = () => {
    return (
        <div className="tw-flex tw-flex-col tw-min-h-screen" role={"banner"}>
            {/* Header */}
            <header className="tw-w-full tw-bg-white tw-shadow-md tw-p-4">
                <Container fluid className="tw-flex tw-justify-between tw-items-center">
                    <div className="tw-flex tw-items-center">
                        <Link to="/">
                            <img src="/logo.jpg" alt="Logo" className="tw-h-10 tw-mr-3 tw-rounded-sm" />
                        </Link>{" "}
                        <Nav>
                            <Nav.Link className="tw-text-3xl tw-font-bold tw-hidden md:tw-block tw-text-black tw-no-underline" as={Link} to="/">
                                Social Synapse
                            </Nav.Link>
                        </Nav>
                    </div>
                </Container>
            </header>

            {/* Main Content */}
            <main className="tw-flex-grow container-fluid tw-my-4">
                <Outlet />
            </main>

            {/* Footer */}
            <footer className="tw-w-full tw-bg-light tw-py-4 tw-mt-auto tw-shadow-sm">
                <Container fluid>
                    <Row className="tw-my-2">
                        <Col className="tw-text-center">
                            <Nav className="tw-justify-content-center">
                                <Nav.Link className="tw-mx-2" as={Link} to="/">
                                    Home
                                </Nav.Link>
                                <Nav.Link className="tw-mx-2" as={Link} to="/privacy-policy">
                                    Privacy Policy
                                </Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="tw-text-center">&copy; 2024 Social Synapse. All rights reserved.</Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
};
