import "github-markdown-css/github-markdown.css";
import "./custom-markdown.css";

import { Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams } from "react-router-dom";

export const LegalText = () => {
    const { doc } = useParams<{ doc: string }>();
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        console.log("Fetching", doc);
        fetch(`/legal/${doc}.md`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(text => {
                console.log(text);
                setMarkdown(text);
            })
            .catch(error => {
                console.error(`Error fetching ${doc}:`, error);
            });
    }, [doc]);

    return (
        <Container className="tw-my-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="tw-p-4">
                        <div className="markdown-body">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
