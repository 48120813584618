import "./index.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Landingpage } from "./pages/landingpage/Landingpage";
import { Layout } from "./pages/layout/Layout";
import { LegalText } from "./pages/legaltext/LegalText";
import { NoPage } from "./pages/NoPage";
import React from "react";
import ReactDOM from "react-dom/client";

// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "", // Set the default path to load the Frontpage
                element: <Landingpage />
            },
            {
                path: "test/:doc",
                element: <LegalText />
            },
            {
                path: "*",
                element: <NoPage />
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />{" "}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
